//
// Screenreaders
//

.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}

// Collapse
.collapse {
  -webkit-transition: max-height 0.35s ease;
  -moz-transition: max-height 0.35s ease;
  transition: max-height 0.35s ease;
  max-height: 0;
  overflow: hidden;

  &.open,
  &.show {
    -webkit-transition: max-height 0.35s ease;
    transition: max-height 0.35s ease;
    max-height: 100%;
    .resources-list & {
      max-height: 100%;
    }
  }
  &:not(.show) {
    /* display: none; */
  }
}

.img-responsive {
  max-width: 100% !important;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

.img-grayscale {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  transition: filter 0.25s ease;
  /* Disable grayscale on hover */
  &:hover {
    -webkit-filter: grayscale(0);
    filter: none;
  }
}

/*!
 * SIZES
 */

@include pad("top");
@include pad("bottom");
@include pad("right");
@include pad("left");

@include margin("top");
@include margin("bottom");
@include margin("right");
@include margin("left");

.m-t-auto {
  margin-top: auto;
}
.m-r-auto {
  margin-right: auto;
}
.m-b-auto {
  margin-bottom: auto;
}
.m-l-auto {
  margin-left: auto;
}

// responsive utilities

// Visibility utilities
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  @include responsive-invisibility();
}

.visible-xs {
  @media (max-width: 576px) {
    @include responsive-visibility();
  }
}
.visible-sm {
  @media (min-width: 768px) and (max-width: 991px) {
    @include responsive-visibility();
  }
}
.visible-md {
  @media (min-width: 992px) and (max-width: 1199px) {
    @include responsive-visibility();
  }
}
.visible-lg {
  @media (min-width: 1200px) {
    @include responsive-visibility();
  }
}

.hidden-xs {
  @media (max-width: 576px) {
    @include responsive-invisibility();
  }
}
.hidden-sm {
  @media (min-width: 577px) and (max-width: 991px) {
    @include responsive-invisibility();
  }
}
.hidden-md {
  @media (min-width: 992px) and (max-width: 1199px) {
    @include responsive-invisibility();
  }
}
.hidden-lg {
  @media (min-width: 1200px) {
    @include responsive-invisibility();
  }
}

.hidden {
  @include responsive-invisibility();
}

.img-shadow {
  border-radius: 10px;
  -webkit-box-shadow: $picture-shadow;
  box-shadow: $picture-shadow;
  overflow: hidden;
}
.media-shadow {
  border-radius: 10px;
  -webkit-box-shadow: $picture-shadow;
  box-shadow: $picture-shadow;
  overflow: hidden;
}

.img-block {
  width: 100%;
  height: auto;
  display: block;
}

.shadow-1 {
  -webkit-box-shadow: $picture-shadow;
  box-shadow: $picture-shadow;
}
.shadow-2 {
  -webkit-box-shadow: $client-logo-shadow;
  box-shadow: $client-logo-shadow;
}

.separator {
  padding-top: $size-9;
  padding-bottom: $size-9;
  opacity: 0.6;
  display: block;
  position: relative;
  &:before {
    position: absolute;
    width: 50px;
    border-top: 1px solid transparent;
    content: "";
    left: 50%;
    margin-left: -25px;
  }
}

.show-on-hover {
  @media screen and (min-width: ($grid-breakpoint)) {
    visibility: hidden;
  }
}
*:hover {
  > .show-on-hover,
  > div > .show-on-hover,
  > div > div > .show-on-hover {
    visibility: visible;
  }
}

.clickable {
  cursor: pointer;
}

.center-block {
  display: block;
  margin: 0 auto;
}

/* not really a utility - just added it here until further dicussion*/
// html {
//   scroll-behavior: smooth;
// }
