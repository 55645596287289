.sci-user-detail{ 
  h2{
    font-size: 1.375rem;
    padding-top: 1rem;
    font-family: $font-base;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $font-weight-light;
    line-height: 1.625rem;
    margin-bottom: $size-3;
    margin-top: $size-2;
  }
  
  h3{
    font-family: $font-base;
    font-size: 1.125rem;
    font-weight: $font-weight-medium;
    line-height: 1.375rem;
    //margin-left: 0.875rem;
    padding-top: $size-12;
    margin-top: -$size-12;
  }

  fieldset{
    border:none;
    padding-top: 0.75rem;    
    padding-bottom: $size-2;
  }

  .form-group{
    margin-bottom: $size-3;
    font-size: $font-size-sm;

    .feedback{
      position: relative;
      
      .form-text{
        position: absolute;

        .error-title{
          font-weight: $font-weight-medium;
          color: $almost-black;
        }
      }
    }

    .sci-form-control-group{
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      &:focus-within .clear-icon,
      &:hover .clear-icon{
        visibility: visible;
      }

      .form-control{
        &[type=text], &[type=password]{
          padding-right: 1.8rem;           
          &.unmasked{
            padding-right: 3.4rem;
          }
        }
      }

      .clear-icon, .password-show-icon{
        cursor: pointer;
        position: absolute;
        right: .25rem;
        text-decoration: none;
        border: none;
        font-size: 1rem;
        color: $blue-primary;
        background-color: transparent;
        padding: 0;
        margin: 0;
        width: $size-3;
        height: $size-3;
        line-height: 1.6rem;
        text-align: center;
        overflow: hidden;
        top: 4px;
        &:active:not(:disabled), 
        &:focus{
          background-color: $blue-89;
          color: $blue-primary;
          outline: 2px solid $blue-primary;
          outline-offset: -2px;
        }

        &:hover{
          color: $blue-27;
          outline: 0px;
          outline-offset: -2px;
        }

        i{
          margin-left: 1px;
          margin-top: 1px;
        }
      }

      .clear-icon{
        visibility: hidden;
      }
      
      .clear-icon-more-padding{
        right: 2rem;
      }
      
      // To support Micrsoft Edge focus-within
      @supports (-ms-ime-align: auto) {
        .form-control:focus + .clear-icon, .clear-icon:focus{
          visibility: visible;
        }
      }

      // To support Micrsoft Internet Explorer 11 focus-within
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
        .form-control:focus + .clear-icon, .clear-icon:focus{
          visibility: visible;
        }
      }
     
    }

    .sci-form-control-group.sci-info-hint{
      & ~ .feedback .info {
          display: none;
          font-size: 0.75rem;
          line-height: 1rem;
          font-weight: $font-weight-normal;
          color: #151515;
          i{
            font-size: 1.125rem;
            line-height: 1.2rem;
            vertical-align: bottom;
          }
      }
      &:focus-within{
        ~ .feedback{
          .validationFailure{
            display: none;
          }
  
          .info{
            display: inline-block;
          }
        }

        input{
          border-bottom: #1c8ce3;
          box-shadow: inset 0 -4px 0 #1c8ce3, inset 0 0 0 1px #1776BF !important;
        }
      }
    }

    .input-group.sci-form-control-group .clear-icon{
      right: 8.3rem;
      z-index: 9;
    }
    
    .sci-radio-button.disabled{
      cursor: not-allowed;

      .custom-control{
        pointer-events: none;
        opacity:  .5;
      }
    }
  }  

  .sci-fieldset-section{
    max-width: 25rem;
    padding-left: 0px;
  }

  .sci-addaction-btn{
    float: right;
    margin-right: -7rem
  }

  footer{
    position: sticky;
    bottom: 0;
    background-color: white;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.16);
    height: $size-7;
    padding: $size-1 $size-3;
    min-width: calc(100vw - 1rem);
  }

  .dlt-icon-info, .dlt-icon-check{
    color: $blue-50;
  }

  .sci-checkall-btn{
    padding-top: 0;
    padding-bottom: 0;
    font-size: .75rem;
    i{
      padding-right: .2rem;
      font-size: .875rem;
      line-height: 1.2rem;
    }
  }

  textarea{
    max-width: 100%;
  }

  .inside-tab-navigation ~ section h3{
    padding-top: 13rem;
    margin-top: -13rem;
  }
}

.sci-view-user-detail{
  .form-group{
    margin-bottom: $size-3;  
    .form-control-plaintext, figure{
      padding-left: 0px;
    }
  }
}

.userImage{
  background-color: $blue-primary;
  color: white;
  text-align: center;
  line-height: 2.5rem;
  >i{
    font-size: 1.5rem;
    margin: auto;
  }
}

.otp_auth{
  .info_message{
    font-size: $font-size-base-xs;
    .dlt-icon-info{
      margin-right: 0.15rem;
      font-size: $font-size-base;
      vertical-align: bottom;
    }
    .dlt-icon-warning{
      margin-right: 0.3rem;
      color: $warning-color;
      font-size: $font-size-base;
    }
  }
}

.input-search {
  .form-control-sm, .form-group-sm .form-control {
    height: 1.5rem;
    font-size: 0.75rem;
  }
}

.user-search{
  line-height: 0.3rem;
}

.user-input{
  padding-right: 1.5rem !important;
}

.search-lens{
  visibility: visible !important;
  font-size: 0.75rem !important;
  height: 1rem !important;
  &:focus{
    background-color: white !important;
    outline: 0px !important;
  }
}

.search-close{
  font-size: 0.75rem !important;
  right: 1.5rem !important;
  height: 1rem !important;
  &:focus{
    background-color: white !important;
    outline: 0px !important;
  }
}

.search-icon{
  vertical-align: top;
}

@media screen and (min-width: 992px) {
  .sci-user-roles-grid-container{
    max-width: calc(100vw - 17rem);
    overflow-x: auto;
    margin-left: -0.75rem;
  }
  
  .sci-user-detail .sci-table-of-contents ~ section{
    max-width: calc(100vw - 17rem);
  }
}

.modalRolesTable{
  width: 45.625rem;
}

.modalArticle {
  height: 37.5rem; 
  overflow-y: auto; 
  margin-bottom: -1.938rem !important;
  margin-right: -2rem;
}

.modalRoleColumns{
  min-width: 9.375rem;
}

.modalFooter {
  min-width: unset !important;
  box-shadow: none !important;
  bottom: 0px !important; 
  justify-content: flex-end !important;
}

