.sci-nav-tabs-bar{
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: sticky;
  top: 8.5rem;
  background: #fff;
  z-index: 1;
  margin-left: -2px;
  margin-right: -2px;
  li > a{
    display: block;
    color: $gray-text;
    text-decoration: none;
    padding: $size-1 $size-2;
    border-bottom: 2px solid $gray-80;
    &:hover, &:focus{
      border: 2px solid $blue-primary;
      padding: 0.375rem 0.875rem .5rem;
      outline: none;
    }
    &.nav-menu-active{
      border-bottom: 2px solid $blue-primary;
      font-weight: $font-weight-medium;
      &:focus{
        border-width: 0 0 2px;
        padding: $size-1 $size-2;
      }
      &:hover{
        border: 2px solid $blue-primary;
        padding: 0.375rem 0.875rem .5rem;
        outline: none;
      }
    }
  }
}