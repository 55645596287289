.sci-appswitcher{
  width: 275px;
  padding: 0;
  &.dropdown-menu{
    left: 16px!important;
    margin-top: -$size-1;
  }
  @media(min-width: $grid-breakpoint){
    width: 23.5rem;
    max-width: none;
    &.dropdown-menu{
      left: auto!important;
      right: .1rem;
      &.arrow-top{
        &:before{
          left: auto;
          right: 15px;
        }
        &:after{
          left: auto;
          right: 16px;
        }
      }
    }
  }

  .sci-appswitcher-inner{
    overflow-y: auto;
    max-height: calc(100vh - 280px);
    padding: 1rem 0;
    @media(min-width: $grid-breakpoint){
      max-height: calc(100vh - 100px);
    }
    &::-webkit-scrollbar {
      width: $size-1;/* for vertical scrollbars */
      height: $size-1;/* for horizontal scrollbars */
    }
    &::-webkit-scrollbar-track {
      background: $gray-90;
      border-radius: $size-1;
    }
    &::-webkit-scrollbar-thumb {
      background: $gray-60;
      border-radius: $size-1;
    }
  }

  .switcher-apps{
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    padding: 0 1rem;
    >a{
      padding: 0;
      width: 6.5rem;
      height: 6.5rem;
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      color: $gray-text;
      border: 2px solid transparent;
      font-size: 12px;
      margin: 1rem 1rem 0 0;
      // font-weight: 600;
      &:hover, &:focus{
        text-decoration: none;
        border-color: $blue-primary;
        outline: none;
      }
      &:active{
        background-color: $dropdown-link-active-bg;
      }
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      &:nth-child(3n+3){
        margin-right: 0;
      }
    }
    svg{
      display: block;
      margin: .5rem auto .25rem;
      width: 40px;
      height: 40px;
      @media(min-width: $grid-breakpoint){
        width: 48px;
        height: 48px;
      }

      ~span{
        line-height: 1rem;
      }
    }
    + .switcher-apps{
      margin-top: 1rem;
      position: relative;
      >h6{
        display: flex;
        flex: 0 100%;
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
        margin-bottom: 1rem;
        margin-top: 0;
      }
      &:before{
        content: '';
        display: block;
        border-top: 1px solid $gray-90;
        width: 100%;
        margin-bottom: 1rem;
      }
      >a{
        &:nth-child(-n+3) {
          margin-top: 0;
        }
        &:nth-child(3n+3) {
          margin-right: 1rem;
        }
        &:nth-child(-n+4) {
          margin-top: 0;
        }
        &:nth-child(3n+4) {
          margin-right: 0;
        }
      }
    }
  }

  .dropdown-menu-right{
    right: .5rem;      
    margin-top: -.3rem;
  }
}