.landing-page {
  .fullpage-image {
    margin-right: -1rem;
    margin-left: -1rem;
    padding-top: 6rem;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;

    > .container {
      min-height: 21.9vh;
    }

    @media (min-width: $grid-breakpoint) {
      &:first-of-type {
        padding-top: $masthead-height * 3;
        > .container {
          min-height: 11.7vh;
        }
      }
    }

    + .pad-top-size-8 {
      padding-top: 0;
    }
  }

  .centered-article {
    p:first-of-type {
      @extend .lead;
    }
    h4 {
      color: $blue-35;
    }
  }

  .product-cta {
    padding-bottom: 0;

    .container {
      h1 {
        margin-bottom: 1rem;
      }

      ul,
      form {
        text-align: left;
      }

      > p:first-of-type {
        @extend .lead;
      }
      p {
        text-align: center;
      }
    }

    &:last-of-type {
      padding-bottom: 4.5rem;

      .container + .svg-wrap {
        display: none;
      }
    }
  }
}

.even-odd-list {
  > .container {
    img {
      @extend .img-responsive;
    }
    p:nth-child(even) {
      margin: 0 2em 2em 0;
    }

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      @extend .row;

      > li:nth-child(odd) {
        @extend .col-lg-7;
      }

      > li:nth-child(even) {
        @extend .col-lg-5;
      }
    }
  }
}

//Tables

//coupon
.tablelp {
  background-color: #f6f9fb;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  .cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
    border-width: 8px;
    border-style: solid;
    border-color: white;
  }

  h1 {
    margin: 0;
    font-size: 3em;
  }
  h4,
  p {
    margin: 0;
  }
}

//package

.package {
  display: flex;
  margin-left: -10%;
  padding: 0;
  width: 120%;
  border-spacing: 0;

  @media (max-width: $grid-breakpoint) {
    margin-left: 10%;
    width: 80%;
  }

  .gap {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;

    @media (max-width: $grid-breakpoint) {
      padding: 10px 0;
    }

    p:first-of-type {
      font-weight: 400;
      font-size: 1em;
    }
    .highlight {
      background-color: #a0b909;
    }
  }
  .header-cell {
    margin: 0 0 -5px;
    padding: 20px 0;
    width: 100%;
    height: 80px;
    border-width: 5px;
    border-style: solid;
    border-color: white;
    background-color: #e0e2e4;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.15);
    word-break: normal;
    font-weight: bold;
    font-size: 18px;
  }
  .cell {
    margin: 0 0 -5px;
    padding: 23px;
    width: 100%;
    height: 240px;
    border-width: 5px;
    border-style: solid;
    border-color: white;
    background-color: #f6f9fb;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.15);
    text-align: left;
  }
  .highlight-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    height: 20px;
    border-width: 5px;
    border-style: solid;
    border-color: white;
    background-color: white;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    word-break: normal;
    font-weight: bold;
    font-size: 20px;
  }
}
