.sci-overlay-list{
    margin: -.3rem -1rem;
    a{
        border: none;
        font-size: $font-size-base-sm;
        color: $gray-text;
        cursor: pointer;
        line-height: 2rem;
        padding-bottom: 0;
        padding-top: 0;
    }
    
    .menu-icons{
        font-size: 1rem;
        padding-right: .5rem;
        vertical-align: text-top;
    }
}

.sci-product-dropdown-menu{
    box-shadow: 0 2px 12px 2px rgba(0,0,0,.12);
    border: none;
    border-radius: 0;
    &.bs-popover-bottom{
        margin-left: -6.15rem;
        margin-top: .75rem;
        .arrow{
            left: auto !important;
            right: 1rem;
        }
    }
}

.sci-product-dropdown-menu-sm{
    box-shadow: 0 2px 12px 2px rgba(0,0,0,.12);
    border: none;
    border-radius: 0;
    &.bs-popover-bottom{
        margin-left: -2.5rem;
        margin-top: .75rem;
        .arrow{
            left: auto !important;
            right: 1rem;
        }
    }
}

.sci-overlay-menu-section{
    overflow-y: auto;
    max-height: calc(100vh * 0.8129);
    &:not(:first-child){
        padding: .5rem 0 0 0;
    }

    a{
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 17.5rem;
        white-space: nowrap;
    }

    .sci-overlay-menu-group-label{
        font-weight: $font-weight-medium;
        font-size: $font-size-base-sm;
        line-height: 1.25rem;
        padding: .5rem 0 .25rem 1rem;
    }

    .sci-active-dropdown-item{
        border-left: 4px solid $blue-primary !important;
        padding-left: 0.75rem;
        &:hover{
            border-left-width: 2px !important;
            padding-left: 0.875rem;
        }
    }
}

.sci-overlay-menu-section::-webkit-scrollbar
{
	width: $size-1;
}

.sci-overlay-menu-section::-webkit-scrollbar-track
{
	background: $gray-90;
    border-radius: $size-1;
}

.sci-overlay-menu-section::-webkit-scrollbar-thumb
{
    background: $gray-60;
    border-radius: $size-1;
}

#sci-environments-first{
    .sci-env-first-div{
        display: inline-block;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
}



