$gray-text: var(--gray-text, $almost-black);
$text-muted: var(--text-muted, $gray-60);
$link-color: var(--link-color,$blue-primary);
$link-hover-color: var(--link-color-hover, $blue-35);
$link-hover-decoration: none;
$inline-message-text: #151515;

$error-color: $red-44;
$info-color: $blue-50;
$warning-color: $orange-56;
$success-color: $green-38;