.alert{
  background-color: white;
  -webkit-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.24);
  font-size: $font-size-sm;
  line-height: 1.5rem;
  padding: .75rem 2.5rem .75rem 3rem;
  position: relative;
  pointer-events: auto;
 
  &:before{
    display: inline-block;
    font-family: "dlt-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1.25rem;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    margin-right: .25rem;
    font-size: 1.5rem;
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
  }

  .close{
    position: absolute;
    z-index: 10;
    right: 1rem;
    top: .9rem;
    text-decoration: none;
    color: $gray-50;
    background: none;
    border: 0;
    padding: 1px 2px;
    margin: 0;
    -webkit-appearance: none;
    font-size: 1rem;
    height: 1.25rem;
    line-height: 1.25rem;
    &:hover, &:focus{
      box-shadow: inset 0 0 0 2px $blue-primary;
      outline: none;
    }
    &:active{
      background-color: $blue-89;
    }
  }

  &.alert-error, &.alert-danger{
    border-left: 4px solid $error-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-error;
      color: $error-color;
    }
  }
  &.alert-success{
    border-left: 4px solid $success-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-success;
      color: $success-color;
    }
  }
  &.alert-info{
    border-left: 4px solid $info-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-info;
      color: $info-color;
    }
  }
  &.alert-warning{
    border-left: 4px solid $warning-color;
    &:before{
      content: $font-dlt-icons-dlt-icon-warning;
      color: $warning-color;
    }
  }

  .alert-title{
    line-height: $line-height-base;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    margin-bottom: .25rem;
  }
}

// alerts wrapper
.alerts{
  position: fixed;
  z-index: 9999;
  max-height: 100vh;
  overflow-y: auto;
  padding: 1.5rem;
  margin: 0 auto;
  width: 100%;
  left: 0;
  pointer-events: none;
  .btn , .close{
    pointer-events: auto;
    cursor: pointer;
  }
  >.top{
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  >.right{
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    >.alert{
      min-width: 17.5rem;
      max-width: 35rem;
    }
  }

  >.center{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    >.alert{
      min-width: 17.5rem;
      max-width: 35rem;
    }
  }
  
}

