.sci-list-group-header{
  font-size: $font-size-sm;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: $size-2 $size-1;
  background-color: white;
  border-bottom: 2px solid $gray-90;
  border-top: 1px solid $gray-90;
}

.sci-list-group{
  font-size: $font-size-sm;
  margin-bottom: $size-2;  
  min-height: 6rem;
  @media screen and (max-width: ($grid-breakpoint - 1)) {
    border-top: 1px solid $gray-90;
  }
  .sci-list-group-item{
    padding: $size-2 $size-1;
    box-shadow: inset 0 -1px 0 $gray-90;
    &:hover{
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $blue-primary;
    }
    p{
      margin-bottom: 0;
    }
    .btn{
      padding-top: 0; 
      padding-bottom: 0; 
    }
    .btn-link.btn-lg{
      width: $size-4;
      height: $size-4;
      line-height: 1;    
      margin-right: 12px;
      margin-left: $size-1;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 0;
      outline-offset: -2px;
      &:hover{
        border-color: #1776bf;
        -webkit-box-shadow: inset 0 0 0 1px $blue-primary;
        -moz-box-shadow: inset 0 0 0 1px $blue-primary;
        box-shadow: inset 0 0 0 1px $blue-primary;
        outline: 0;
      }
      &:active{
        background-color: $blue-89;
      }

      i{
        font-size: $size-3;
      }

      a{
        padding: 3px;
      }
    }   
    .highlight-cell{
      border: 1px solid $blue-50;
      border-radius: 2px;
      color: $blue-50;
      font-size: 0.6875rem;
      line-height: 0.8125rem;
      text-align: center;
      margin-left: .2rem;
      padding: .2rem;
    }
    .btn-link:disabled, .btn-link.disabled{
      pointer-events: inherit;
      cursor: not-allowed;
      &:hover{
        box-shadow: none;
        border-color: transparent;
        outline: none;
      }
      &:active{
        background-color: transparent;
      }
    }
    .sci-empty-text::before{
      content: "none";
      color: #4A494A;
      opacity: 0.5;
    }

    .sci-description-row{
      white-space: unset;
      line-height: 1.25rem;
      padding: 1rem;
      border-top: 1px solid #dddddd;      
      word-break: break-word;
    }

    .data-editable {
      &:hover > p{
        border: 2px solid $blue-primary;
        min-width: 8rem;
      }

      p {
        border: 2px solid transparent;
        height: auto;
        line-height: 1.7rem;
        padding: 0 0.5rem;
        word-break: break-word;
      }

      .form-group {
        width: 100%;
      }      

      .datepicker-icon {
        position: absolute;
        right: 0;
        top: 0.1rem;
        border: none;
        outline: none;
        font-size: 1.125rem;
        color: $blue-primary;
        background-color: transparent;
        padding: 0;
        margin: 0;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.6rem;
        cursor: unset;
      }

      .sci-empty-description::before {
        font-style: italic;
        content: 'description';
        opacity: 0.3;
      }

      .sci-empty-date::before {
        font-style: italic;
        content: 'dd/mm/yyyy';
        opacity: 0.3;
      }
    }

    .sci-multiline-cell, .data-editable.sci-multiline-cell {
      p {
        line-height: 1.5;
      }
    }
  }
}


.user-avatar{
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23e5e5e5'%3E%3Cpath d='M12,0 C5.37248,0 0,5.37248 0,12 C0,18.62752 5.37248,24 12,24 C18.62752,24 24,18.62752 24,12 C24,5.37248 18.62752,0 12,0 M12,2.33008 C17.34416,2.33008 21.67008,6.65472 21.67008,12 C21.67008,17.344 17.34512,21.66992 12,21.66992 C6.65584,21.66992 2.32992,17.34512 2.32992,12 C2.32992,6.656 6.65504,2.33008 12,2.33008' id='Fill-10-Copy'%3E%3C/path%3E%3Cpath d='M17.8030737,17.059871 C16.3925417,18.6765462 14.3166414,19.6973926 12,19.6973926 C9.68299989,19.6973926 7.60644858,18.6761276 6.19576985,17.0586097 C6.24672632,17.0453999 6.29906049,17.0325848 6.3528094,17.02016 C8.50850904,16.52224 10.6334861,16.07808 9.61068089,14.19264 C6.5819485,8.608 8.74788899,5.44 11.9993599,5.44 C15.1881057,5.44 17.4090908,8.49024 14.388039,14.19392 C13.3959563,16.06784 15.4454071,16.51328 17.6459105,17.02144 C17.6997021,17.033858 17.7520775,17.0466669 17.8030737,17.059871 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  margin-right: $size-1;
}

.user-new {
  width: 40px;
  height: 40px;

}
.userImg{
  position: relative;
  max-width: 40px;
  &:after{
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23e5e5e5'%3E%3Cpath d='M12,0 C5.37248,0 0,5.37248 0,12 C0,18.62752 5.37248,24 12,24 C18.62752,24 24,18.62752 24,12 C24,5.37248 18.62752,0 12,0 M12,2.33008 C17.34416,2.33008 21.67008,6.65472 21.67008,12 C21.67008,17.344 17.34512,21.66992 12,21.66992 C6.65584,21.66992 2.32992,17.34512 2.32992,12 C2.32992,6.656 6.65504,2.33008 12,2.33008' id='Fill-10-Copy'%3E%3C/path%3E%3Cpath d='M17.8030737,17.059871 C16.3925417,18.6765462 14.3166414,19.6973926 12,19.6973926 C9.68299989,19.6973926 7.60644858,18.6761276 6.19576985,17.0586097 C6.24672632,17.0453999 6.29906049,17.0325848 6.3528094,17.02016 C8.50850904,16.52224 10.6334861,16.07808 9.61068089,14.19264 C6.5819485,8.608 8.74788899,5.44 11.9993599,5.44 C15.1881057,5.44 17.4090908,8.49024 14.388039,14.19392 C13.3959563,16.06784 15.4454071,16.51328 17.6459105,17.02144 C17.6997021,17.033858 17.7520775,17.0466669 17.8030737,17.059871 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.sci-dnd-list-group{
  .sci-list-group-item{
    cursor: grab;
    &.sci-drag-over{
      background-color: white;
      border: 2px dashed $blue-primary;
      color: transparent;
    }
  }
}

.sci-environment-grid .sci-list-group-item{
  line-height: 2rem;
}

