.mktoInvalid{
  @extend .form-control, .textFieldError;
}

select.mktoInvalid{
  @extend .form-control, .textFieldError;
}
.mktoErrorMsg{
  @extend .form-text;
  @extend .validationFailure;
}

.subscribe-newsletter{
  .mktoFormRow.col-sm-6{
    max-width: 100%;
    flex: 0 0 100%;
  }

  &.thankyou{
    position: relative;
    .dismiss{
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background-color: white;
      padding: 1.5rem;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .lead{
        font-weight: bold;
        text-align: center;
      }
    }
  }
}

