.single-release{
  padding: 0;
  @include media-breakpoint-up('md') {
    padding: $size-4;
    -webkit-box-shadow: $picture-shadow;
    box-shadow: $picture-shadow;
  }

  .h2 small{
    font-size: 1rem;
  }

  h2{
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: .5rem;
    margin-bottom: 2rem;
  }

  h3{
    font-size: 1.4rem;
    font-weight: bold;
    color: $blue-35;
  }

  h3, h4, h5{
    margin-top: 3rem;
  }
  h2 + h3{
    margin-top: 0;
  }
  ul{
    padding-left:0;
    margin-left: 18px;
  }
  li > p{
    margin-bottom: 0;
  }
  img{
    max-width: 100%;
    height: auto;
  }
}