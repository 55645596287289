// The dropdown wrapper (`<div>`)
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  // Generate the caret automatically
  @include caret;
  padding-right: 0 !important;
  position: relative;
  line-height: 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  max-width: 280px;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0; // override default ul
  font-size: $font-size-base-sm; // Redeclare because nesting can cause inheritance issues
  color: $body-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);

  &.arrow-top {
    &:before {
      position: absolute;
      top: -7px;
      left: 9px;
      display: inline-block;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $dropdown-border-color;
      border-left: 7px solid transparent;
      border-bottom-color: $dropdown-border-color;
      content: "";
    }
    &:after {
      position: absolute;
      top: -6px;
      left: 10px;
      display: inline-block;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $dropdown-bg;
      border-left: 6px solid transparent;
      content: "";
    }
    &.dropdown-menu-right{
      &:before{
        left:auto;
        right: 15px;
      }
      &:after{
        left:auto;
        right: 16px;
      }
    }
  }
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(up);
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(left);

    &::before {
      vertical-align: 0;
    }
  }
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable no-duplicate-selectors
.dropdown-menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}
// stylelint-enable no-duplicate-selectors

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  margin: .5rem 1rem;
  border-top: 1px solid $gray-90;
}

.dropdown-item + hr {
  margin: 0 1rem !important;
  border-top: 1px dotted $gray-80;
  border-bottom: 0;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border-width: 1px 0 1px 0; // For `<button>`s
  border-color: transparent;
  border-style: solid;
  text-decoration: none;
  &:hover {
    color: $dropdown-link-color;
    text-decoration: none;
    box-shadow: inset 0 0 0 2px $blue-primary;
    // background-color: $dropdown-link-hover-bg;
  }
  &:focus {
    border-color: $gray-50;
    outline: none;
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
    border-color: $gray-50;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }
}

.dropdown-menu.show {
  display: block;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-padding-y $dropdown-item-padding-x;
  margin-bottom: 0; // for use with heading elements
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}
