.sci-toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: $size-2;
    margin-top: 10px;
    margin-bottom: -10px;
    cursor: pointer;
    input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border-width: 1px;
        visibility: visible;
        white-space: nowrap;
        &:checked + .slider-round {
            border: 1px solid $gray-50;
            &:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }
        }        
        &.inactive + .slider-round:before {
            background-color: $gray-50;
        }
        &.active + .slider-round:before {
            left: 0;
        }
        &.active:checked:disabled:hover+.slider-round,
        &.inactive:checked:disabled:hover+.slider-round {
            box-shadow: none;
            border: 1px solid  $gray-50;
        }
    }

    .slider-round {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        border: 1px solid $gray-50;
        border-radius: 34px;
        &::before {
            position: absolute;
            content: '';
            height: 10px;
            width: 10px;
            left: 3px;
            bottom: 2px;
            background-color: white;
            border-radius: 50%;
            background-color: $blue-primary;
        }
    }    

    input:focus + .slider-round,
    &:active .slider-round,
    input.active:checked:active + .slider-round {
        box-shadow: 0 0 0 1px $blue-primary;
        border: 1px solid $blue-primary;
    }
    
    &:hover>input:disabled + .slider-round {
        box-shadow: none;
        border: 1px solid  $gray-50;
    }
    
    .switch-text {
        color: $gray-text;
        font-family: $font-family-base;
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        line-height: 16px;
        text-align: left;
        margin-left: 50px;
        vertical-align: top;
    }

    input:disabled + .slider-round, 
    input:disabled ~ .switch-text {        
        opacity: .5;
        cursor: not-allowed;
    }
}
