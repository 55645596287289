.sci-popover-text {
    font-size: $font-size-sm;
    line-height: 1.25rem;
    .dlt-icon-check, .dlt-icon-check-circle {
        color: $success-color;
    }
    .dlt-icon-close {
        font-size: 0.75rem;
        color: $error-color;
        padding-left: 1px;
        padding-right: 1px;
    }
}

.sci-popover-subtitle {
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
}

.sci-popover-tip {
    .popover-body {
        padding: .5rem .5rem;
    }
}