@mixin bg-color($elem: "bg") {
  @each $name,
  $color in $delite-colors {
    .#{$elem}-#{$name} {
      background-color: map-get($delite-colors, $name);
      @include get-color-yiq(map-get($delite-colors, $name));
    }
  }
}

@mixin text-color() {
  @each $name,
  $color in $delite-colors {
    .text-#{$name} {
      color: map-get($delite-colors, $name);
    }
    a.text-#{$name}:hover {
      color: map-get($delite-colors, $name);
    }
  }
}

// contrast checker
@mixin get-color-yiq($background) {
  // $r: red($background);
  // $g: green($background);
  // $b: blue($background);
  
  // $yiq: (($r*299) + ($g*587) + ($b*114))/1000;
  
  // @if ($yiq >= 128) {
  //   color: $gray-text;
  // }
  // @else {
  //   color: #fff;
  // }
}

