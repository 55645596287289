.sci-hero {
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
    padding: 1.5rem;
    margin: 2rem 0 1.25rem;
    .img-circle{
        background: $blue-primary;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        .dlt-icon-profile{
            color: white;
            font-size: 2rem;
            margin-left: 1.5rem;
            line-height: 4.375rem;
        }
    }
    .edit-img{
        &:hover{
            background: white;
            border:2px solid $blue-primary;
            cursor: pointer;
        }
        &:active {
            background:$blue-89;
        }
        .edit_label{
            margin-left: 1.625rem;
            line-height: 4.875rem;
            color:$blue-primary;
        }
    }

    .user-label{
        font-size: 1.375rem;
        margin-left: 0.125rem;
        font-weight: $font-weight-medium;
    }

    .user-sub-label{
        margin-left: 0.125rem; 
    }
    .sci-hero-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: self-start;
        height: auto;
        max-width: 70%;

        [contenteditable] {
            border: 2px solid transparent;
            margin-left: -2px;
            outline: 0;
            display: inline-block;
            white-space: nowrap;
            max-width: calc(100vw - 15rem);
            max-height: 2.25rem;
            overflow: hidden;
            &:focus, &:hover {
                margin-left: 0;
                padding-left: 8px;
                border: 2px solid $blue-primary;
                padding-right: 2rem;
                + i {
                    display: inline-block;
                    position: relative;
                    right: 2.5rem;
                    top: -.5rem;
                }
            }

            + i {
                display: none;
                color: $blue-primary;
                margin: 0 4px;
            }
        }

        h2 {
            margin-bottom: 0;
            font-size: 1.375rem;
            line-height: 2rem;
            font-family: $font-base;
            font-weight: $font-weight-medium;
        }
        
        p {
            font-size: $font-size-base;
            line-height: 1.25rem;
            word-break: break-word;
        }

        

        .sci-fieldset {
            margin: 0;
            border: 0;
            padding: 0;

            .btn-link{
                color: $gray-text;
                padding: .1rem .2rem;
                height: 2rem;
                width: 2rem;
                &:hover, &:focus{
                    outline: none;
                    box-shadow: inset 0 0 0 1px $blue-primary;
                    border: 1px solid $blue-primary;
                }
                &:active{
                    background-color: $blue-89;
                }
            }
        }

        .sci-default-label {
            font-weight: $font-weight-medium;
            font-size: $font-size-sm;
            display: inline-block;
            vertical-align: baseline;
            margin: 0 .8rem 0 0;
        }
    }

    .sci-hero-actions {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
        line-height: 1.75rem;
        margin-bottom: 0;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
    
}