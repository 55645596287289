.sci-empty-state{
  background: white;
  padding: $size-3;
  margin-bottom: $size-4;
  color: $gray-70;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  i{
    font-size: 2rem;
  }
}

.sci-empty-state-error {
  img {
    width: 15.625rem;
  }
  .error-title {
    font-weight: $font-weight-medium;
    font-size: $size-3;
    line-height: $size-4;
    margin-top: $size-2;
  }
  .error-message {
    font-size: $font-size-base;
    line-height: $size-3;
  }
}