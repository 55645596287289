.sci-search-dropdown {
  position: relative;
  .sci-search-results-dropdown {
    top: 100%;
    z-index: $zindex-dropdown;
  }
  .search-results {
    display: block;
    margin: 0 0 0 0;
    padding: 0;
    list-style: none;
    left: 0;
    z-index: 1000;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    //border: 1px solid #E5E5E5;
    border-radius: 0;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);

    li a {
      cursor: pointer;
    }
  }
}

.input-group-search {
  position: relative;
  .form-control {
    padding-right: 2rem;
  }
  .icon-button {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .form-control-sm + .icon-button {
    top: 5px;
  }
}

.signup-form {
  padding: 1.35rem;
  .form-group {
    margin-top: 38.5px;
    margin-bottom: 0;
    .environment-name {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      width: 119px;
      text-align: left;
    }
    .form-control {
      padding-right: 1.8rem;
    }
    .feedback {
      position: relative;
      margin-top: 0.47%;
      margin-bottom: 1.17%;
      .form-text {
        position: absolute;
        margin-top: 0.47%;
        margin-bottom: 1.18%;
        .info {
          color: #333333;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          text-align: left;
        }
      }
      .info{
        font-size: $font-size-base-xs;
        margin-top: 4px;
        position: absolute;
      }
    }
    .environment-group {
      height: 32px;
      margin-bottom: 0 !important;
	  border: 1px solid #666666;
      .environment-name-textbox {
        display: inline-block;
        width: 65%;
        .default-cursor-style {
          width: 100%;
          text-align: right;
          padding-right: 5% !important;
        }
        .clear {
          position: absolute !important;
          right: 0 !important;
          width: 3%;
        }
        span {
          left: 61.4% !important;
        }
      }
      @media (max-width: $form-max-width) {
        .environment-name-textbox {
            display: block;
            width: 100%;
        }
        .environment-name-textbox span {
          left: 92.5% !important;
      }
      }
      .rectangle-copy-12 {
        background-color: #E5E5E5;
        border: 1px solid #666666;
        height: 32px;
      }
      .environment-label-name {
        display: inline-block;
        width: 35%;
        float: right;
        clear: both;
		border: none;
    border-bottom: 1px solid #666666;
    background-color: #E5E5E5;
        text-align: center;
        .softwareag-cloud {
          color: #666;
          text-align: left;
          font-family: Roboto;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 17px;
        }
        .environment-label {
          vertical-align: bottom;
        }
      }
      @media (max-width: $form-max-width) {
        .environment-label-name {
          display: block;
          width: 100%;
          float: left;
          clear: both;
          // margin-bottom: 24px;
      }
      }
      @media (max-width: $form-max-width) {
      .environment-group {
        height: 64px !important;
      }
    }
    }
    .next {
      color: #ffffff;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      width: 384px;
      text-align: center;
    }
    .background {
      background-color: #1776bf;
      border: 1px solid #1776bf;
      width: 100%;
      height: 40px;
      span {
        font-family: Roboto;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 17px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .sci-logo-svg {
    margin-top: 80px;
    height: 25px;
  }
  @media(min-width: $grid-breakpoint){
      .sci-logo-svg {
	   height: 25px;
	   margin-top: 80px;
	  }
    }
  .log-in-to-your-account {
    color: #424242;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    margin-top: 25.04px;
  }
  .no-account {
    margin-top: 6.5px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
    .links-regular {
      color: #1776bf;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
    }
  }
  .feedback{
   .info {
          color: #333333;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          text-align: left;
        }
  }
}

.sci-router-form {  
  font-family: $font-base;
  margin-top: 5rem;

  .sci-logo-svg {
    height: 1.56rem;
  }

  .sci-page-title {
    color: $gray-30;
    font-size: 1.375rem;
    font-weight: $font-weight-medium;
    line-height: 2rem;
    margin-top: 1.275rem;
  }

  .sci-alternative-action {
    color: $almost-black;
    font-size: $font-size-sm;
    margin-top: .25rem;
    line-height: 1.25rem;

    .sci-link {
      color: $blue-primary;
      margin-left: .5rem;
    }
  }

  .sci-user-detail{
    margin-top: 2.5rem;
  }

  .validation-and-link .feedback {
    .form-text {
      width: calc(100% - 10.5rem) !important;
      font-size: .75rem;
    }

    .sci-link {
      font-size: $font-size-sm;
      color: $blue-primary;
      text-align: right;
      float: right;
      cursor: pointer;
      line-height: 1rem;
      margin-top: .25rem;
    }
  
    .dlt-icon-info {
      font-size:  $font-size-base;
      line-height: .875rem;
    }
  }
}

.sci-success-alert{
  position: absolute;
  z-index: 100;
  width: 40rem;    
  top: 1rem;
}