
.header.navigation {
  background-color: $blue-primary;
  color: white;
  padding: 0 $size-3;
  @include box-shadow($navbar-box-shadow);
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 1030;

  .navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }

  .navbar-brand {
    height: $masthead-height;
    max-width: $masthead-brand-width;
    margin-right: $size-2;
    margin-left: 3.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    
    .img-responsive {
      max-width: 12.5rem !important;
      max-height: 100%; 
    } 
  }

  .navbar-toggle {
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 1040;
    &:focus{
      outline: none;
    }
  }
  .nav-item{
    a:not(.dropdown-item){
      color: white;
      &:hover{
        background-color: $blue-35;
      }      
      &:focus{
        outline: none;
        box-shadow: inset 1px 0 0 white, inset -1px 0 0 white;
      }
    }
    .dropdown-toggle{
      padding-right: .5rem !important;
      height: 100%;
    }
    .dropdown-menu:not(.default){
      padding: 0;
      background-color: $blue-35;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border:0;
      margin-top: -4px!important;
      .dropdown-item{
        color: white;
        line-height: 32px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        font-size: $font-size-base;
        &:hover, &:active, &:focus{
          color: white;
          background: $blue-27;
        }
        &.active{
          color: white;
          background: $blue-20;
          -webkit-box-shadow: none;
                  box-shadow: none;
        }
      }
      .dropdown-divider{
        border-color: rgba(255,255,255, .15);
        margin: 0;
      }
    }
    .dropdown-item{
      img{
        width: 24px;
        height: 24px;
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    &.active > a,
    &.active .dropdown > a {
      border-color: white !important;
      background-color: $blue-35;
      @media(min-width: $grid-breakpoint){
        -webkit-box-shadow: inset 0 4px 0 white;
                box-shadow: inset 0 4px 0 white;
      }
    }
    .dropdown.show > a{
      border-color: white !important;
      background-color: $blue-35;
    }
  }

  .navbar-right{
    .dropdown-item{
      border: 0;
      line-height: 2rem;
      padding-bottom: 0;
      padding-top: 0;
    }
    .dropdown-info{
      margin-top: .5rem;
      line-height: 1.25rem;
      pointer-events: none;
      border: none;
    }
    .dropdown-menu-right{
      right: .1rem;
      margin-top: -.5rem;
      box-shadow: 0 2px 12px 2px rgba(0,0,0,.12);
    }
  }

  #sci-environments-menu-dropdown{
    .dropdown-toggle{
      padding: 0 .75rem !important;
      line-height: 1.2;
      .d-flex{
        align-items: center;
        div{
          #sci-environments-first{
            div{
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 12.77rem;
              white-space: nowrap;
            }
          }
        }
      }
      small{
        opacity: .7;
      }
    }

    a.nav-link:focus{
      box-shadow: none;
    }
  }

  #sci-help-menu{
    #sci-version-info{
      font-size: .75rem;
      color: $gray-40;
      &.hidden+.dropdown-divider{
        display: none;
      }
    }
  }

  #sci-help-menu, #sci-appswitcher{
    a.nav-link>i{
      height: 1.5rem;
      margin-bottom: .5rem;
    }
  }
  
  .text-truncate {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .text-nowrap {
    white-space: nowrap;
  }
}

@media screen and (max-width: ($grid-breakpoint - 1)) {
  .navbar-collapse {
    position: absolute;
    z-index: 1030;
    top: $masthead-height;
    left: 0;
    min-height: calc(100vh - #{$masthead-height});
    width: $masthead-nav-width;
    background-color: $blue-primary;
    -webkit-transform: translateX(-$masthead-nav-width);
            transform: translateX(-$masthead-nav-width);
    -webkit-transition: -webkit-transform .4s ease-in-out;
    transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;

    .navbar-nav {
      //max-height: 100%;
      //overflow-y: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
              flex-flow: column nowrap;
    }

    &.open {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    .dropdown{
      width: 100%;
      .dropdown-menu{
        //width: 100%;
        left: 4px;
        right: auto;
      }
      .dropdown-menu.arrow-top.dropdown-menu-right{
        &:before{
          left: 15px;
          right: auto;
        }
        &:after{
          left: 16px;
          right: auto;
        }
      }
    }
  }

  .nav-cover {
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    top: $masthead-height;
    bottom: 0;
    width: 0;
    left: 0;
    -webkit-transition: width .4s ease-in-out;
    transition: width .4s ease-in-out;

    .open & {
      width: 100%;
    }
  }
}





@media screen and(min-width: $grid-breakpoint) {

  // headroom
  .headroom {
    will-change: transform;
    -webkit-transition: -webkit-transform 200ms linear;
    transition: -webkit-transform 200ms linear;
    transition: transform 200ms linear;
    transition: transform 200ms linear, -webkit-transform 200ms linear;
  }
  .header.navigation {
    &.headroom--pinned {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    &.headroom--unpinned {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      .sci-cloud &{
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
      }
    }
  }
  .header.navigation {
    padding: 0 $size-3;

    .navbar-toggle {
      display: none;
    }

    .navbar-collapse {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      .navbar-right {
        margin-left: auto;
      }
    }

    .navbar {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
              flex-flow: row nowrap;

      .nav-item a {
        border: 0 solid transparent;
        width: auto;
      }
      .nav-item.active > a{
        -webkit-box-shadow: inset 0 4px 0 white;
                box-shadow: inset 0 4px 0 white;
      }
    }

    .navbar-brand {
      margin-left: 0;
    }

    .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
              flex-flow: row nowrap;
      height: 100%;
    }
  }

}