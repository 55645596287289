$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding:               1rem !default;

$modal-dialog-margin:               0 !default;
$modal-dialog-margin-y-sm-up:       0 !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  white !default;
$modal-content-border-color:        rgba($black, .05) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-box-shadow-xs:       0 .25rem .75rem .5rem rgba($black, 0.24) !default;
$modal-content-box-shadow-sm-up:    0 .5rem 1.5rem .5rem rgba($black, 0.24) !default;
$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .6 !default;
$modal-header-border-color:         rgba($black, .1) !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            1rem !default;
$modal-header-padding-x:            1rem !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                          1140px !default;
$modal-lg:                          800px !default;
$modal-md:                          500px !default;
$modal-sm:                          300px !default;

$modal-fade-transform:              translate(0, -100%) !default;
$modal-show-transform:              translate(0, -50%) !default;
$modal-transition:                  top .3s ease-out, transform .3s ease-out !default;

