.documentation-page{
  background-color: $gray-95;
  margin: 0 -1rem -1rem;
  padding-bottom: 5rem;
  padding-top: 120px;

  h1, h2, h3, h4, h5{
    font-family: $font-base;
  }
  .main-content{
    background-color: white;
    box-shadow: 0 4px 4px rgba(0,0,0,0.01), 0 8px 8px rgba(0,0,0,0.01), 0 16px 16px rgba(0,0,0,0.03), 0 32px 32px rgba(0,0,0,0.05), 0 32px 64px rgba(0,0,0,0.1), 0 -3px 32px rgba(0,0,0,0.1);
    transition: transform 0.3s cubic-bezier(0.75, 0.01, 0.32, 0.99),filter 0.3s cubic-bezier(0.75, 0.01, 0.32, 0.99);
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    padding: 60px;
    max-width: 1200px;
    border-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;

    .section-header {
      margin: -60px -60px 60px;
      padding: 40px 60px;
      background-image: linear-gradient(to right, #1c8ce3 0, #5faeec 100%);
      h1{
        font-size: 3rem;
        margin: 0;
        color: white;
        font-weight: 300;
      }
      + p{
        font-size: 1.4em;
        font-weight: 300;
      }
    }
    h2{
      font-size: 2rem;
      padding-top: 0;
      margin-top: 24px;
      padding-bottom: 16px;
      border-bottom: 1px dotted $gray-80;
      font-weight: 200;
      color: $blue-primary;
      margin-bottom: 2rem;
    }
    h3{
      color: $blue-primary;
      font-weight: 300;
      margin-top: 4rem;
    }

    ol{
      list-style-type: decimal;
      li{
        ol{
          list-style-type: lower-alpha;
          li{
            ol{
              list-style-type: lower-roman;
            }
          }
        }
      }
    }

    .table-responsive{
      width: 100%;
      margin-bottom: $size-3;
      overflow-y: hidden;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 0rem;
      -webkit-overflow-scrolling: touch;      
      table{
        width: 100%;
        margin-bottom: $size-2;
        color: $almost-black;
        border-collapse: collapse;
        border-spacing: 0;
        tbody tr:nth-of-type(odd){
          background-color: rgba(0,0,0,0.05);
        }
        tr>td{
          word-break: break-word;
        }
        th, td{
          padding: .75rem;
          vertical-align: top;
          border-top: 1px solid $gray-90;
        }
      }
    }
  }
}