.sci-toolbar{
  margin-top: $size-2;
  margin-bottom: $size-2;
  .btn-link{    
    color: $link-color;
    font-size: .75rem;
    padding-top: 0;
    padding-bottom:0; 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;    
    line-height: $size-3;
    height: $size-3;
    border: 1px solid $blue-primary;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    .icon{
      font-size: 1.1rem;
      margin-right: 4px;
    }
    &:hover, &:focus{
      outline: none;
      box-shadow: inset 0 0 0 1px $blue-primary;
    }
    &:active{
      background-color: $blue-89;
    }
  } 

  .btn-link-primary{
    color: white;
    background-color: $blue-primary;
    &:hover, &:focus{
      color: $link-color;
      background-color: white;
      outline: none;
      box-shadow: inset 0 0 0 1px $blue-primary;
    }
    &:active{
      color: white;
      background-color: $blue-27;
    }
  }

 /*  .form-control-sm, .form-group-sm .form-control{
    height: calc(1.3125rem + 2px + 1px);
    font-size: .75rem;
  }

  .input-group-search .icon-button {
    top: 2px;
    right: 5px;
  } */

  .input-group-search {
    .form-control-sm, .form-group-sm .form-control {
      height: 1.5rem;
      line-height: 1.5rem;
      padding-right: 3rem;
      font-size: .75rem;;
      &:focus {
        padding-right: 3rem;
      }
    }

    .icon-button,
    .clear-icon {
      width: 1.2rem;
      height: 1.2rem;
      top: 2px;
      right: 4px;
      font-size: 1rem;
      line-height: 1.2rem;
      color: #1776bf;
      padding: 0 .1rem .2rem;
      &:active:not(:disabled),
      &:focus {
        background-color: #cde6f9;
        outline: 2px solid #1776bf;
        outline-offset: -2px;
      }
      &:hover {
        outline-offset: -2px;
        outline:none;
      }
    }

    .clear-icon {
      display: none;
      position: absolute;
      right: 1.5rem;
      color: inherit;
    }

    &:focus-within .clear-icon,
    .form-control:focus + .clear-icon {
      display: inherit;
    }
  }
}