//
// Icon Font: sag_product_icons
//

@font-face {
  font-family: "sag_product_icons";
  src: url("fonts/sag_product_icons.eot");
  src: url("fonts/sag_product_icons.eot?#iefix") format("embedded-opentype"),
       url("fonts/sag_product_icons.woff2") format("woff2"),
       url("fonts/sag_product_icons.woff") format("woff"),
       url("fonts/sag_product_icons.ttf") format("truetype"),
       url("fonts/sag_product_icons.svg#sag_product_icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "sag_product_icons";
    src: url("fonts/sag_product_icons.svg#sag_product_icons") format("svg");
  }
}

.sci-prod-icon-adamos:before,
.sci-prod-icon-mft:before,
.sci-prod-icon-alfabet:before,
.sci-prod-icon-aris:before,
.sci-prod-icon-cloud-container:before,
.sci-prod-icon-cloudcontainer:before,
.sci-prod-icon-cumulocity-iot:before,
.sci-prod-icon-metering:before,
.sci-prod-icon-cumulocity:before,
.sci-prod-icon-mycloud:before,
.sci-prod-icon-wm-agile-apps:before,
.sci-prod-icon-wm-api:before,
.sci-prod-icon-apiportal:before,
.sci-prod-icon-devportal:before,
.sci-prod-icon-apigateway:before,
.sci-prod-icon-wm-b2b:before,
.sci-prod-icon-b2b:before,
.sci-prod-icon-wm-integration:before,
.sci-prod-icon-integrationcloud:before,
.sci-prod-icon-webmethodsioint:before,
.sci-prod-icon-wm-integration-io:before {
  display: inline-block;
  font-family: "sag_product_icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

$font-sag_product_icons-adamos: "\f10c";
$font-sag_product_icons-alfabet: "\f100";
$font-sag_product_icons-aris: "\f101";
$font-sag_product_icons-cloud-container: "\f109";
$font-sag_product_icons-cumulocity-iot: "\f102";
$font-sag_product_icons-metering: "\f10a";
$font-sag_product_icons-mft: "\f10b";
$font-sag_product_icons-mycloud: "\f103";
$font-sag_product_icons-wm-agile-apps: "\f104";
$font-sag_product_icons-wm-api: "\f105";
$font-sag_product_icons-wm-b2b: "\f106";
$font-sag_product_icons-wm-integration: "\f107";
$font-sag_product_icons-wm-integration-io: "\f108";


.sci-prod-icon-adamos:before {
   content: $font-sag_product_icons-adamos; 
  }
.sci-prod-icon-alfabet:before {
  content: $font-sag_product_icons-alfabet;
}

.sci-prod-icon-aris:before { 
  content: $font-sag_product_icons-aris; 
}
.sci-prod-icon-cloud-container:before, 
.sci-prod-icon-cloudcontainer:before {
  content: $font-sag_product_icons-cloud-container; 
}

.sci-prod-icon-cumulocity-iot:before,
.sci-prod-icon-cumulocity:before{ 
  content: $font-sag_product_icons-cumulocity-iot; 
}
.sci-prod-icon-metering:before{ 
  content: $font-sag_product_icons-metering; 
}

.sci-prod-icon-mycloud:before { 
  content: $font-sag_product_icons-mycloud; 
}

.sci-prod-icon-wm-agile-apps:before { 
  content: $font-sag_product_icons-wm-agile-apps; 
}

.sci-prod-icon-apiportal:before,
.sci-prod-icon-devportal:before,
.sci-prod-icon-apigateway:before,
.sci-prod-icon-wm-api:before { 
  content: $font-sag_product_icons-wm-api; 
}

.sci-prod-icon-wm-b2b:before,
.sci-prod-icon-b2b:before { 
  content: $font-sag_product_icons-wm-b2b; 
}

.sci-prod-icon-integrationcloud:before,
.sci-prod-icon-wm-integration:before { 
  content: $font-sag_product_icons-wm-integration; 
}

.sci-prod-icon-webmethodsioint:before,
.sci-prod-icon-wm-integration-io:before { 
  content: $font-sag_product_icons-wm-integration-io; 
}
.sci-prod-icon-mft:before { 
  content: $font-sag_product_icons-mft;
}

