.sci-update-account-modal{
    .modal-body {
        max-height: calc(84vh - 9rem);
        overflow-y: auto;
    }

    h5 {
        font-family: $font-base;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
    }
}

.sci-account-info {
    h3 {
        font-size: 1.125rem;
        font-weight: $font-weight-medium;
    }
    
    .feedback .form-text .error-title {
        font-weight: 500;
    }
}

.sci-accounts-grid, .sci-accounts-env-grid {

    .sci-list-group-header {
        padding: .79rem .5rem;
    }  

    .sci-list-group .sci-list-group-item {
        line-height: 1.8rem;

        i.loading-mask::after {
            height: 1.25rem;
            width: 1.25rem;
            top: calc(50% + 0.25rem);
            left: calc(50% - 0.25rem);
        }
    }
    
    .feedback .form-text .error-title {
        font-weight: 500;
    }  
}

.sci-env-product-modal {

    .modal-header .refresh-icon {
        right: 5rem;
        position: absolute;
    }

    .modal-body {
        max-height: calc(84vh - 5rem);
        min-height: 13.5rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .sci-list-group-item {
        & > div {
            min-height: 3.25rem;
        }

        .loading-mask {
            min-height: unset;
            &::after {
                height: 2.5rem;
                width: 2.5rem;
                top: unset;
                left: unset;
            }
        }
    }   

    div.icon-holder {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        flex-basis: 2.5rem;
        flex-shrink: 0;
        margin: 0 0.5rem;

        i {
            font-size: 2.5rem;
            line-height: 1;
            color: $blue-primary;
        }
    }   

    .sci-toggle-switch .switch-text {
        vertical-align: top;
    }

    i.dlt-icon-warning {
        color: $warning-color;
    }
}
