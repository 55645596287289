.navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
.navbar-container{
  // max-width: 1280px;
  margin: 0 auto;
}

.navbar-nav {
  .nav-item, .nav-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }  
  .nav-icon > a{
    font-size: 24px;
    padding: .75rem .75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
      align-items: center;
    color: white;
    &:hover, &:focus{
      text-decoration: none;
      background-color: $blue-35;
    }
    @media(max-width: $grid-breakpoint - 1){
      width: 100%;
      padding: 7px $size-1;
    }
  }
  .nav-icon.dropdown.show > a{
    background-color: $blue-35;
  }
  .nav-item {
    a:not(.dropdown-item):not(.btn) {
      text-decoration: none;
      font-size: 16px;
      border: 0;
      border-left: 4px solid transparent;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
      align-items: center;
      line-height: 1.5;
      width: 100%;
      white-space: nowrap;
      padding: 12px $size-2;
      .products-menu &{
        padding: 12px .4rem;
      }
    }
  }
  .nav-btn{
    padding-top: 1em;
    padding-left: .75rem;
    .btn{
      padding: 0.3rem 1.5rem;
    }
    .btn + .btn{
      margin-left: .5rem;
    }
    @media(max-width: $grid-breakpoint){
      .btn{
        width: 220px!important;
      }
      .btn + .btn{
        margin-left:0; 
        margin-top: .5rem;
      }
    }
  }
}

.product-page .hide-on-product-page{
  display: none;
}


.products-menu{
  padding: 0 $size-2;
  top: $masthead-height;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 1029;
  background: white;
  -webkit-transition: background .5s ease;
  transition: background .5s ease;
  @include box-shadow($navbar-box-shadow);

  .navbar-nav{
    min-height: $size-6;
  }
  .product-nav-logo-holder{
    display: block;
    height: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    > img{
      height:25px;
      width: auto;
      display: block;
    }
  }
  .dropdown-holder{
    padding: 0;
    -ms-flex-item-align: center;
        align-self: center;
  }
  .nav-item{
    a:not(.btn){
      color: $gray-text;
      
      &.dropdown-toggle{
        padding: 0.3rem 1.5rem 0.3rem 0;
        //border: 1px solid $blue-primary;
        border-radius: .2rem;
        min-height: 40px;
        &:after{
          position: absolute;
          right: 5px;
        }
        img{
          max-height: 20px;
          width: auto;
        }
      }
      // &:hover{
      //   color: $blue-primary;
      // }
    }
    &.active{
      a{
        color: $blue-primary;
        -webkit-box-shadow: inset 0 -4px $blue-primary;
                box-shadow: inset 0 -4px $blue-primary;
        pointer-events: none;
      }
    }
    @media(max-width: $grid-breakpoint){
      width: 100%;
      .dropdown{
        width: 100%;
        .dropdown-menu{
          width: 100%;
        }
      }
    }
    &.try-free{
      padding:0.45em 0;
      display: none;
      .btn{
        padding: 0.3rem 1rem;
        // min-width: 122px;
      }
    }
  }

  @media screen and(min-width: $grid-breakpoint) {
    .nav-item.try-free{
      display:block;
      margin-left: auto;
      +.nav-item.try-free{
        margin-left: 1rem;
      }
    }
    &.headroom--unpinned {
      -webkit-transform: translateY(-$masthead-height);
              transform: translateY(-$masthead-height);
    }
  }
}

.tabs-menu{
  &.headroom--not-top {
    position: fixed;
  }
}



.products-dropdown{

  .dropdown-toggle::after{
    border-top-color: $blue-65;
    border-bottom-color: $blue-65;
  }

  &.show{
    background-color: white;
    @include box-shadow($dropdown-box-shadow);
    .dropdown-toggle{
      border-bottom-color: white!important;
      border-bottom-left-radius: 0!important;
      border-bottom-right-radius: 0!important;
      position: relative;
      z-index: $zindex-dropdown + 1;
    }
    .dropdown-menu{
      //border-top:0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-color: transparent;
      top: -4px!important;
      min-width: 100%;
      padding: 0;
      .dropdown-item{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        img{
          max-height: 19px;
        }
      }
      .dropdown-item.active{
        background: $blue-primary;
        color: white;
        pointer-events: none; 
      }
    }
  }

 
}


