.user-info{
  .dropdown-toggle{
    display: block;
    padding-left: .25rem !important;
    padding-right: .25rem !important;
    margin-right: -.25rem;
    &::after{
      content: '';
    }
  }
  .img-circle{
    height: 2.5rem;
    width: 2.5rem;
  }
  .user-details{
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
  }

  .dropdown-menu-right{
    right: -.1rem !important;      
  }

  span.img-circle.top-level{
    color: white;
    text-align: center;
    line-height: 2.7rem;
    >i{
      font-size: 1.5rem;
      margin: auto;
    }
  }
}