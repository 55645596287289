.loading-mask {
  position: relative;
  pointer-events: none;
}

.loading-mask::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color:rgba(235,235,235,0.15);
  z-index: 9995;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg);
  }
}
.loading-mask::after {
  content: '';
  position: absolute;
  border-width: 3px;
  border-style: solid;
  border-color: transparent $blue-primary $blue-primary;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  animation: 1s linear 0s normal none infinite running spin;
  filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));
  z-index: 9999;
}