.svg-wrap {
  position: relative;
  width: 100%;
  > svg {
    position: absolute;
    width: 100%;
    fill: white;
  }
  &.bottom {
    right: 0;
    bottom: 0;
    @media (min-width: $grid-breakpoint) {
      bottom: -1px;
    }
    left: 0;
  }
  &.top {
    top: 0;
    right: 0;
    left: 0;
  }
}
