$buttons: (
  primary: (bg: $blue-primary, color: white, border: $blue-primary, hbg: white, hcolor:$blue-primary, hborder: $blue-primary, acolor: white, abg: #0F4C7B, aborder: #0F4C7B),
  blue-primary: (bg: $blue-primary, color: white, border: $blue-primary, hbg: white, hcolor:$blue-primary, hborder: $blue-primary, acolor: white, abg: $blue-14, aborder: $blue-14),
  secondary: (bg: white, color: $blue-primary, border: $blue-primary, hbg: white, hcolor:$blue-primary, hborder: $blue-primary, acolor:$blue-primary, abg: $blue-89, aborder: $blue-primary),
  ghost-primary: (bg: white, color: $blue-primary, border: $blue-primary, hbg: white, hcolor:$blue-primary, hborder: $blue-primary, acolor:$blue-primary, abg: $blue-89, aborder: $blue-primary),
  ghost-default: (bg: transparent, color: white, border: white, hbg: $blue-primary, hcolor:white, hborder: $blue-primary, acolor: white, abg: $blue-14, aborder: $blue-14),
  default: (bg: white, color: $blue-primary, border: $blue-primary, hbg: white, hcolor:$blue-primary, hborder: $blue-primary, acolor:$blue-primary, abg: $blue-89, aborder: $blue-primary),
  danger: (bg: $red-44, color: white, border: $red-44, hbg: white, hcolor:$red-44, hborder: $red-44, acolor: white, abg: $red-21, aborder: $red-21),
  success: (bg: $green-24, color: white, border: $green-24, hbg: white, hcolor:$green-24, hborder: $green-24, acolor: white, abg: $green-14, aborder: $green-14),
  emphasis: (bg: $pink-accent, color: white, border: $pink-accent, hbg: white, hcolor:$pink-accent, hborder: $pink-accent, acolor: white, abg: $pink-24, aborder: $pink-24),
);



@mixin btn-ghost() {
  @each $name,
  $btn in $buttons {
    &.btn-ghost-#{$name} {
      background-color: transparent;
      color: map-get($btn, bg);
      border: 1px solid transparent;
      border-color: currentColor;
      text-decoration: none;
      border-radius: 0;
      // padding: .375rem 1.5rem;
      &:hover{
        background-color: map-get($btn, bg);
        color: map-get($btn, color);
        text-decoration: none;
      }
    }
  }
}

@mixin dlt-btn(){
  @each $name,
  $btn in $buttons {
    &.btn-#{$name} {
      @include button-variant(map-get($btn, bg), map-get($btn, border), map-get($btn, color), map-get($btn, hbg), map-get($btn, hborder), map-get($btn, hcolor),map-get($btn, abg), map-get($btn, aborder), map-get($btn, acolor));
    }
  }
}


// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($background, $border, $color, $hover-background, $hover-border, $hover-color, $active-background, $active-border,$active-color) {
  color: $color;
  background-color: $background;
  border-color: $border;
  outline: 1px solid transparent;
  outline-offset: -2px;
  transition: all .25s ease-in;
  &:hover:not(:disabled):not(.disabled), &:focus:not(:disabled):not(.disabled) {
    color: $hover-color;
    background: $hover-background;
    border-color: $hover-border;
    outline: 1px solid $hover-border;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    background-image: none;
    border-color: $active-border;
    &:hover, &:focus{
      outline: 1px solid $active-border;
    }
  }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    //color: $color-hover;
    background-color:$active-background;
    //border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width white, 0 0 0 ($btn-focus-width * 2) $color;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width white, 0 0 0 ($btn-focus-width * 2) $color;
      } @else {
        box-shadow: 0 0 0 $btn-focus-width white, 0 0 0 $btn-focus-width $color;
      }
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}
