.sci-prompt{
  padding: 2rem;
  .modal-header{
    font-size: 1.25rem;
    font-weight: $font-weight-medium;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 0 1rem 0;
    line-height: 1.74rem;
    border: 0;
    &:before{
      display: inline-block;
      font-family: "dlt-icons";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      font-size: 1.5rem;
      position: relative;
      left:0;
      top:0;
      margin-right: .5rem;
    }

    .close{
      padding: .1rem .15rem 0rem;
      margin: -.25rem -.25rem -1rem auto;
      font-size: 1.5rem;
      cursor: pointer;
      &:hover, &:focus{
        box-shadow: inset 0 0 0 2px $blue-primary;
        outline: none;
      }
      &:active{
        background-color: $blue-89;
      }
    }
    
  }

  &.error-prompt, &.danger-prompt{
    border-left: 4px solid $error-color;
    .modal-header:before{
      content: $font-dlt-icons-dlt-icon-error;
      color: $error-color;
    }
  }
  &.success-prompt{
    border-left: 4px solid $success-color;
    .modal-header::before{
      content: $font-dlt-icons-dlt-icon-success;
      color: $success-color;
    }
  }
  &.info-prompt{
    border-left: 4px solid $info-color;
    .modal-header::before{
      content: $font-dlt-icons-dlt-icon-info;
      color: $info-color;
    }
  }
  &.warning-prompt{
    border-left: 4px solid $warning-color;
    .modal-header::before{
      content: $font-dlt-icons-dlt-icon-warning;
      color: $warning-color;
    }
  }

  .modal-body{
    padding: 0;
    font-size: $font-size-sm;
    p:last-child{
      margin-bottom:0;
    }
  }

  .modal-footer{
    border-top: transparent;
    padding: 1.5rem 0 0 0;
  }
}

.sci-modal-xl .modal-lg {
   max-width: 83.33vw;
}

.sci-modal-default .modal-lg {
   max-width: 37rem;
}