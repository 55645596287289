.product-pricing {
  background-color: $blue-20;
  background-image: radial-gradient(
    circle at 27% bottom,
    $blue-50 0%,
    $blue-20 60%
  );
  margin-bottom: -205px;
  color: white;
}

.pricing-table {
  ul {
    text-align: left;
    font-size: $font-size-sm;
  }

  font-size: 0.875rem;
  .collapse,
  .collapsing {
    font-size: 0.875rem;
  }
}

.pricing-table .collapse,
.pricing-table .collapsing {
  position: relative;
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  .row.panel {
    background-color: $gray-95;
  }
  .row.panel:nth-child(odd) {
    background-color: white;
  }
  &.show,
  &.open {
    max-height: 100%;
  }
}

.pricing-header {
  position: relative;
  min-height: 120px;
  width: 100%;
  h4 {
    margin: 0;
    font-weight: bold;
    color: $blue-primary;
  }

  .panel {
    min-height: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.05);
  }
  .col-sm:nth-child(1) > .panel {
    border-top-left-radius: 10px;
  }
  .col-sm:last-child > .panel {
    border-top-right-radius: 10px;
  }
}
#pricing-header,
#pricing-header-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1028;
  .panel {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }

  &.headroom--not-top {
    position: fixed;
    top: 112px;
    background-color: $blue-35;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    @media (min-width: $grid-breakpoint) {
      top: 112px;

      &.nomenu {
        top: 48px;
      }
    }
    .panel {
      min-height: 10px;
      background-color: transparent;
      color: white;
      padding-top: 1rem;
      padding-bottom: 1rem;
      -webkit-box-shadow: none;
      box-shadow: none;

      h4 {
        font-size: 16px;
        font-weight: normal;
        color: inherit;
      }
    }
    &.headroom--unpinned {
      @media (min-width: $grid-breakpoint) {
        -webkit-transform: translateY(-$masthead-height);
        transform: translateY(-$masthead-height);
        &.nomenu {
          -webkit-transform: translateY(-48px);
          transform: translateY(-48px);
        }
      }
    }
  }
}

.pricing-footer {
  .col-sm:nth-child(2) > .panel {
    border-bottom-left-radius: 10px;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }
  .col-sm:last-child > .panel {
    border-bottom-right-radius: 10px;
  }
  .panel {
    padding: $size-4;
    -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);

    .btn {
      width: 100%;
    }
    .dropdown {
      display: block;
    }
  }
}

.pricing-footer-buy {
  .col-sm:nth-child(2) > .panel {
    border-bottom-left-radius: 10px;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border-bottom-right-radius: 0px;
  }
  .col-sm:last-child > .panel {
    border-bottom-right-radius: 10px;
  }
  .panel {
    padding: $size-4;
    margin-top: -10px;
    -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
    border-bottom-right-radius: 10px;
    .btn {
      width: 100%;
    }
    .dropdown {
      display: block;
    }
  }
}

.currency-dollar {
  display: none;
}

.sag-cur-usd {
  .currency-dollar {
    display: block;
  }
  .currency-euro {
    display: none;
  }
}
