.sci-page-header{
  padding-top: 2rem;
  position: sticky;
  top: 4rem;
  background: white;
  z-index: 1;
  margin-left: -2px;
  margin-right: -2px;
  h1{
    font-size: 1.5rem;
    font-family: $font-base;
    margin-bottom: 0;
    padding-bottom: 0.5em;
    // padding-left: 1rem;
  }
}