
/*!
 * Buttons
 */

 .btn{
   text-decoration: none;
  //  @include btn-ghost;
   @include dlt-btn;
 }

 .btn-block{
   display: block;
   margin: 0 auto;
 }

 

 .btn-success{
   color: white;
 }

 .btn.btn-link{
   &:hover{
     color: $blue-primary;
   }
 }

 .btn-link.spin-icon{
   pointer-events: none;
   color: $link-color!important;
   .icon{
     height: 31px;
    @extend .spin-animation;
   }
 }

.spin-animation{
  -webkit-animation: icon-spin 1.5s infinite linear;
  animation: icon-spin 1.5s infinite linear
}

.btn-emphasis{
  border-radius: 16px!important;
  outline: none!important;
  &:focus, &:hover, &:active{
    outline: none!important;
  }
}



@-webkit-keyframes icon-spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

@keyframes icon-spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}



.to-the-top {
  position: fixed;
  right: 48px;
  bottom: 20px;
  z-index: 38;
  font-size: 2rem;
  display: block;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  border: 1px solid $gray-60;
  border-radius: 50%;
  background-color: transparent;
  color: $gray-60;
  text-align: center;
  opacity: .5;
  transition: all 0.4s ease;
  cursor: pointer;
  
  &:hover, &:focus {
    border-color: transparent;
    background-color: $blue-primary;
    color: white;
    opacity: 1;
    outline: none;
  }
}