tag-input {
  .error-messages {
    position: absolute;
    .error-message {
      span {
        color: $almost-black;
        &:before {
          @extend %form-validation-shared;
          content: $font-dlt-icons-dlt-icon-error;
          color: $error-color;
        }
      }
    }
  }
}

.sci-tag-theme.ng2-tag-input {
  border: 1px solid gray !important;
  padding: 1px 4px !important;
  min-height: 2rem !important;
  line-height: 1.2rem !important;
  &.ng2-tag-input--focused {
    border: 1px solid $blue-primary !important;
    box-shadow: inset 0 0 0 1px $blue-primary !important;
  }

  &.ng2-tag-input--invalid {
    border-bottom: $error-color;
    box-shadow: inset 0 -3px 0 $error-color !important;
  }
  .ng2-tag-input__text-input {
    height: 1.75rem !important;
    font-size: $font-size-sm !important;
  }

  delete-icon, .bootstrap {
    &:hover {
      -webkit-transform: scale(1.2) translateY(-1px) !important;
      transform: scale(1.2) translateY(-1px) !important;
    }
  
    svg{
      height: 1rem !important;
      vertical-align: middle !important;
      path {
        fill: #0f4c7b !important;
      }
    }
  }

  tag,
  .ng-trigger {
    height: 1.5rem !important;
    line-height: 1.4rem !important;
    font-size: $font-size-sm !important;
    border-radius: 0px !important;
    background: transparent !important;
    border: 1px solid #666666;
  
    .tag__text {
      color: #333;
    }
  
    &:hover {
      background: transparent !important;
      border: 1px solid #1776bf !important;
      box-shadow: inset 0 0 0 1px #1776bf !important;
    }
  }

  @supports (-moz-appearance: none) {
    .ng2-tags-container {
      margin-top: 1px;
      margin-bottom: -1px;
    }

    tag,
    .ng-trigger {
      line-height: 1.4rem !important;
    }
  }

  input::placeholder {
    font-style: italic;
    color: $input-placeholder-color;
    opacity: 1;    
  }
}
