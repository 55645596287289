.card{
  background: white;
  padding: $size-3;
  border-radius: $size-1;
  overflow: hidden;
  margin-bottom: $size-4;
  color: $gray-text;

  .fit-v &{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    width: 100%;
    > .row{
      height: 100%;
    }
  }
 
  .card-image{
    margin:-1.5rem 0 -1.9rem -1.5rem;
  }
  h4{
    font-weight: bold;
    color: $blue-35;
  }

  .card-title{
    color: $blue-35;
    // min-height: 56px;
    line-height: 1.2;
    font-weight: bold;
  }

  .card-label{
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: white;
    background-color: $blue-20;
    padding: 3px 16px 2px;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  .card-image{
    margin: 0 (-$size-3);
    
  }
  .card-image-square, .card-image-rectangle{
    margin: 0 (-$size-3);
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    &:first-child{
      margin-top: -$size-3;
      margin-bottom: $size-2;
    }
  }

  .card-image-rectangle{
    padding-bottom: 58.5%;
    
  }
  .card-footer{
    margin-top: auto;
    padding-top: 1rem;
  }
  hr{
    border:0;
    border-top: 1px solid $gray-90;
    margin: $size-3 0;
  }
}




.card-horizontal{
  padding: 0;
  .card-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top:0;
    right:0;
    bottom: -1px;
    left:0;
    margin: 0;
  }
  .card-content{
    padding: 1.5rem;
  }
}


.counter-card{
  min-height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  padding: 1rem $size-4;
  margin-left: $size-3;
  margin-right: $size-3;
  h4{
    font-weight: bold;
    margin-top: $size-8;
  }
  &:after{
    counter-increment: section;
    content: counter(section);
    position: absolute;
    top:-26px;
    right: $size-2;
    font-size: 13rem;
    font-weight: bold;
    opacity: .075;
    color: currentColor;
  }
}

.news-title{
  color: $blue-20;
  min-height: 48px;
  line-height: 1.2;
}


.sci-card{
  background: white;
  padding: 0;
  margin-bottom: $size-6;
  color: $gray-text;
  -webkit-box-shadow: $box-shadow-sm;
          box-shadow: $box-shadow-sm;
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  width: 100%;
  -webkit-transition: -webkit-box-shadow .25s ease;
  transition: -webkit-box-shadow .25s ease;
  transition: box-shadow .25s ease;
  transition: box-shadow .25s ease, -webkit-box-shadow .25s ease;
  &:after{
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: .25rem;
    bottom:0;
    left: 0;
    background-color: transparent;
  }

  .sci-info &{
    &:after{
      background-color: $info-color;
    }
  }
  .sci-success &{
    &:after{
      background-color: $success-color;
    }
  }
  .sci-error &{
    &:after{
      background-color: $error-color;
    }
  }
  .sci-warning &{
    &:after{
      background-color: $warning-color;
    }
  }
  
  .sci-card-title{
    font-size: 1rem;
    margin: 0;
    font-weight: 300;
  }
  .sci-product-description{
    font-size: $font-size-sm;
    margin-bottom:0;
  }
  .sci-product-img{
    max-width: 160px;
    height: auto;
    margin: 0 auto;
    display: block;
    /**
      better fix for https://itrac.eur.ad.sag/browse/SCI-1186
    **/
    // width: 100%;
  }
  
  &:hover{
    background-color: $blue-98;
    -webkit-box-shadow: $box-shadow-sm, inset 2px 0 0 $blue-primary,inset -2px 0 0 $blue-primary,inset 0 2px 0 $blue-primary, inset 0 -2px 0 $blue-primary;
            box-shadow: $box-shadow-sm, inset 2px 0 0 $blue-primary,inset -2px 0 0 $blue-primary,inset 0 2px 0 $blue-primary, inset 0 -2px 0 $blue-primary;
  }
  + .sci-product-expiry-label{
    bottom: 0;
    display: block;
    position: absolute;
    left: $size-2;
    font-size: $font-size-base-xs;
	  height: $size-6;
    margin: 0;
    margin-right: 0.875rem;
    color: $inline-message-text;
    i{ 
      font-size: $font-size-base-sm;
    }
    .sci-info &{
      i{
        color: $info-color
      }
    }
    .sci-error &{
      i{
        color: $error-color
      }
    }
    .sci-warning &{
      i{
        color: $warning-color
      }
    }
    .sci-success &{
      i{
        color: $success-color
      }
    }
  }
}

.sci-card-header{
  position: relative;
  padding: $size-2;
  .sci-card-title{
    text-align: center;
    padding: 0 25px;
    min-height: 46px;
  }
  .sci-card-actions{
    position: absolute;
    right:2px;
    top:2px;
    >button, >.btn{
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;
      background: transparent;
      font-size: 1rem;
      line-height: 1rem;
      margin: .9rem .5rem .5rem;
      &:hover,
      &:focus{
        outline: none;
        box-shadow: inset 0 0 0 1px $blue-primary;
        border-color: $blue-primary;
      }
      &:active{
        background-color: $dropdown-link-active-bg;
      }
    }
  }
}

.sci-card-block{
  padding: 0 $size-2 $size-2;
  &:first-child{
    padding-top: $size-2;
  }
}

.sci-card-footer{
  padding: $size-2;
  margin-top: auto;
}

.sci-card-icon{
  text-align: center;
  font-size: 4.5rem;
  color: $blue-primary;
}

.sci-card-expired {
  background: $gray-95;
  pointer-events: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  .sci-card-title, .sci-product-img {
    opacity: 0.5;
  }
  &:hover {
    border: none;
    background-color: $gray-95;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .btn-secondary, .dlt-icon-more-menu{
    pointer-events: auto;
  }
  .btn-secondary:not(:active){
    background: white;
  }
}

.product-link-more-menu .list-group-item {
  &:hover{
    background-color: $blue-89;
  }
}