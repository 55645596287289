.sci-product-card {
    width: inherit;
    height: auto;
    background: white 0% 0% no-repeat;
    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
    padding: 1.5rem 1rem 1rem 1rem;
    position: relative;
    border: 2px solid white;
    outline: 0;
    min-height: 7.5rem;

    .sci-product-card-title {
        display: flex;
        flex-flow: row wrap;
        
        div.icon-holder {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            flex-basis: 2.5rem;
            flex-shrink: 0;
            margin: 0 .5rem;
            
            i {
                font-size: 2.5rem;
                line-height: 1;
                color: $blue-primary;
            }
        }
        
        h3 {
            font-weight: $font-weight-medium;
            font-size: 1.125rem;
            color: $gray-30;
            padding: 0 .5rem;
            padding-right: 1rem;
            margin-bottom: 0;
            margin-top: 0;
            line-height: 1.2;
            display: flex;
            flex-flow: column wrap;
            flex: 1 1;
            align-self: center;
        }
    }
    .sci-product-card-content {
        padding-top: 2.5rem;
        height: auto;
        overflow: hidden;
        min-height: 5.125rem;
        
        p {
            padding: 0 .5rem;
            font-size: .875rem;
            line-height: 1.5;
            margin-bottom: .125rem;
        }

        .btn-sm{
            height: 2rem;
            line-height: 1.6;
        }

        .wait_msg{
            text-align: center;
        }

        .loading-mask {
            width: 7.6rem; 
            &::after {
                height: 1.25rem;
                width: 1.25rem;
                top: calc(50% - 0.6rem);
                left: calc(50% - 0.6rem)
            }
        }
    }

    .sci-product-card-actions {
        padding-top: 1rem;
        display: flex;
        justify-content: flex-end;

        >*+* {
            margin-left: .5rem;
        }

        .sci-more-menu {
            display: inline-block;
            position: relative;
            height: 1.5rem;
            width: 1.5rem;
            button {
                position: absolute;
                color: $blue-primary;
            }
        }

        button, .btn{
            padding: .188rem;
            width: 2rem;
            height: 2rem;
            background: transparent;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: inset 0 0 0 1px $blue-primary;
                border-color: $blue-primary;
            }
            &:active {
                background-color: $dropdown-link-active-bg;
            }

            i {
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        width: calc(100% + 6px);
        height: .25rem;
        bottom: -3px;
        left: -3px;
        right: 6px;
        background-color: transparent;
    }

    .sci-info & {
        &:after {
          background-color: $info-color;
        }
      }
      .sci-success & {
        &:after {
          background-color: $success-color;
        }
      }
      .sci-error & {
        &:after {
          background-color: $error-color;
        }
      }
      .sci-warning & {
        &:after {
          background-color: $warning-color;
        }
      }
     
    + .sci-product-expiry-label {
        bottom: -3.25rem;
        display: block;
        position: absolute;
        left: $size-2;
        font-size: $font-size-base-xs;
        height: $size-6;
        margin: 0;
        margin-right: 0.875rem;
        color: $inline-message-text;
        i { 
            font-size: $font-size-base-sm;
        }
        a {
            text-decoration: none;
            color: $link-color;
            cursor: pointer;
            display: inline-block;
            white-space: nowrap;
            &:hover, &:focus{
                text-decoration: underline;                
                color: $link-color;
                outline: none;
            }
            &:active{
                color: $blue-27;
                text-decoration: none;
            }
        }
        .sci-info &  {
            i {
                color: $info-color
            }
        }
        .sci-error & {
            i {
                color: $error-color
            }
        }
        .sci-warning & {
            i {
                color: $warning-color
            }
        }
        .sci-success & {
            i {
                color: $success-color
            }
        }
    }

    &.sci-product-expired {
        cursor: default;
        .sci-product-card-title {
          opacity: 0.5;
        }
    }
}

.sci-product-headline {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: 2rem;
}

.sci-basic-plan .sci-product-card:after {
    background-image: linear-gradient(90deg, #E1E1E1 0%, #B9B9B9 100%);
}

.animate_fadeOutUpShort {
    animation: fadeOutUpShort;
    animation-duration: 0.5s;
}

.animate_fadeInUpShort {
    animation: fadeInUpShort;
    animation-duration: 0.5s;
}

.animate_fadeOutDownShort {
    animation: fadeOutDownShort;
    animation-duration: 0.5s;
}

.animate_fadeInDownShort {
    animation: fadeInDownShort;
    animation-duration: 0.5s;
}

@keyframes fadeOutUpShort {
    0% {
      opacity: 1
    }
  
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0)
    }
}

@keyframes fadeInUpShort {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 20%, 0);
      transform: translate3d(0, 20%, 0)
    }
  
    to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
    }
}

@keyframes fadeOutDownShort {
    0% {
      opacity: 1
    }
  
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 20%, 0);
      transform: translate3d(0, 20%, 0)
    }
  }

  @keyframes fadeInDownShort {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0)
    }
  
    to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
    }
  }