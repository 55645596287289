.sci-grid{
    width: fit-content;
    border-collapse: collapse;
    border: 1px solid $gray-90;
    tr{
        height: 2.875rem;
        &:nth-child(odd) td{
            background-color: $gray-95;
        }
        
        th{  
            border: 1px solid $gray-90;
            border-bottom-width: 2px;
            padding: .7rem 2rem .7rem .75rem;
            text-align: left;
            font-weight: $font-weight-medium;
            &:first-child{
                border-right-width: 2px;
            }
        }

        td{  
            border: 1px solid $gray-90;
            padding: 0.70rem;
            &:first-child{
                border-right-width: 2px;
                font-weight: $font-weight-medium;
            }
        }
    }

    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label{
        &::before, &::after{
            opacity: 0.5;
            background-color:white;
            cursor: not-allowed;
            box-shadow: none;
        }
    }  
    
    .sci-grid-title{
        float: left;
        font-size: 1.125rem;
        font-weight: $font-weight-medium;
        line-height: 1.375rem;
    }

    .sci-grid-actionbar{
        float: right;
    }
  
    @supports (-moz-appearance:none){ 
        .custom-checkbox .custom-control-label {
            position: absolute;
        }
    }
}