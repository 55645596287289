.landing-page-card-list {
  .container {
    text-align: center;

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: left;
      font-size: 1.3rem;

      @extend .row;
      @extend .justify-content-center;

      > li {
        @extend .col-lg;
        @extend .fit-v;

        > .card {
          p:first-of-type {
            font-size: 1.3rem;
            @extend .card-title;
          }
          > p {
            font-size: 1rem;
          }

          ul {
            padding-left: 1rem;
            list-style: disc;
            font-size: 1rem;

            @extend .text-small;
          }
          li {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
  &:last-of-type {
    .container + .svg-wrap {
      display: none;
    }
  }
}