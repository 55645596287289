.sci-free-forever{
    padding: 2rem;
    .sci-card{
        margin: 1.5rem 0;
        -webkit-box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.16);
        box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.16);
        &:hover {
            cursor: unset;
            background-color: unset;
            -webkit-box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.16);
            box-shadow: 0 0.125rem 0.25rem 1px rgba(0, 0, 0, 0.16);
        }
        &.free-forever-edition{
            border-top: .25rem solid $blue-primary;
            padding-bottom: 1rem;

            .ff-tag{
                color: $blue-primary;
                font-size: .75rem;
                font-weight: $font-weight-medium;
                margin: .5rem 1rem 0;
                line-height: 1.25rem;
            }
        }
        &.free-trial{
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        .ff-header{            
            margin: .5rem 1.5rem .25rem;
        }

        .ff-title{
            font-size: 1.125rem;
            font-weight: $font-weight-medium;
            color: $almost-black;
            line-height: 1.5rem;
            margin-bottom: 0;
        }

        .ff-product{
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
            color: $black;
            line-height: 1.5rem;
        }

        .ff-label{
            font-weight: $font-weight-medium;
            font-size: $font-size-sm;
            color: $almost-black;
            line-height: 1.5rem;
            margin: .5rem 1.5rem 0;
        }

        .ff-description{
            margin-left: 1.5rem;
            font-size: .75rem;
            color: $gray-40;
            line-height: 1rem;
        }

        .ff-note{
            display: none;
        }
    }

    .terms-conditions{    
        .tc-header{
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
            line-height: 1.5rem;
            margin-bottom: .75rem;
        }

        .tc-content {
            border: 1px solid $gray-40;
            height: calc(100vh - 25.5rem);
            overflow-x: hidden;
            overflow-y: scroll;
            margin-bottom: 2px;
            margin-top: 2px;
            padding: .25rem 1rem .5rem 0;

            .ng2-pdf-viewer-container {
                height: auto !important;
                overflow-y: hidden;
                padding-bottom: .5rem;
        
                .page {
                    overflow: hidden;
                }
            }

            iframe {
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
                border: none;
            }
        }

        .tc-instruction{
            line-height: 1rem;
            font-size: 12px;

            i{
                color: $info-color;
                font-size: 1.125rem;
                vertical-align: top;
            }
        }

        .tc-printer-version{
            color: $link-color;
            font-size: $font-size-base;
            cursor: pointer;
        }
    }

    .modal-body {
        max-height: calc(84vh - 9.5rem);
        overflow-y: auto;
    }

    .modal-footer {
        padding-top: 1.5rem;
        .ff-note{
            font-size: $font-size-sm;
        }
    }
}

