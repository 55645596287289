.sci-table-of-contents{
    ul{
      position: fixed;
      top: $size-12;
      right: $size-3;
      background-color: white;
      min-width: 14rem;      
      height: auto;
      margin: 0;
      li{
        line-height: 1.8;
        list-style: none;
        margin-bottom: $size-2;
        padding-left: $size-1;
        border-left: $half-size solid transparent;
        &.active, &:active{
          border-left-color: $blue-primary;
        }
        &:hover{
          border-left-color: $gray-80;
        }
        a{
          font-size: $font-size-base;
          font-weight: $font-weight-medium;
          color: $gray-text;
          text-align: left;
          line-height: $size-3;
          text-decoration: none;
          cursor: pointer;
        }
      }
      
      &.inside-tab-navigation{        
        top: 12.5rem;
      }
    }

    &.inside-tab-navigation{      
      ul{
        top: 13rem;
      }  
    }
}